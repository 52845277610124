import { IUnsuccessfulContentResult } from "../../../core/results/Result";
import { ErrorDetails, ResponseBase } from "./types";
import { UnsuccessfulContentResult } from "../../../core/results/unsuccessful/UnsuccessfulResult";
import { FailureReasonMapper } from "../mappers/FailureReasonMapper";

interface IHeaders {
  authorization: string;
}

export abstract class EndpointMethod {
  private readonly _path: string;
  private _headers: IHeaders | undefined;

  constructor(path: string) {
    this._path = path;
  }

  protected get path(): string {
    return this._path;
  }

  protected get headers(): IHeaders | undefined {
    return this._headers;
  }

  protected abstract execute(): Promise<unknown>;

  public authenticate(token: string): this {
    this._headers = { ...this._headers, authorization: `Bearer ${token}` };

    return this;
  }

  protected mapToUnsuccessfulResult<T>(
    response: ResponseBase<T>,
  ): IUnsuccessfulContentResult<T> {
    if (response.success)
      throw new Error("Cannot map successful response to unsuccessful result!");

    if (!response.error)
      throw new Error("Cannot map unsuccessful result without an error!");

    const error: ErrorDetails = response.error;
    return new UnsuccessfulContentResult<T>({
      code: error.code,
      message: error.message,
      failureReason: FailureReasonMapper.fromErrorReason(error.reason),
    });
  }
}
