import { z } from "zod";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { schemas } from "../api/schema";

type Project = z.infer<
  typeof schemas.Checklab_WebApi_Features_Projects_GetById_V1_GetProjectByIdResponse
> | null;

type ProjectStore = {
  selectedProject: Project | null;
  updateSelectedProject: (project: Project | null) => void;
};

export const useProjectStore = create<ProjectStore>()(
  persist(
    (set, get) => {
      return {
        selectedProject: null,
        updateSelectedProject: (project: Project | null) =>
          set({ selectedProject: project }),
      };
    },
    {
      name: "project-storage",
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
