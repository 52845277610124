import { EndpointMethod } from "./EndpointMethod";
import { ResultFailureReason } from "../../../core/results/Result";
import { UnsuccessfulContentResult } from "../../../core/results/unsuccessful/UnsuccessfulResult";
import { ResponseBase } from "./types";
import { IContentResult } from "../../../core/results/Result";
import { SuccessfulContentResult } from "../../../core/results/successful/SuccessfulResult";

export class PutMethod<T> extends EndpointMethod {
  public async execute(body?: any): Promise<IContentResult<T>> {
    try {
      const result = await fetch(`${this.path}`, {
        method: "PUT",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
          ...this.headers,
        },
      });

      if (!result.ok) {
        const errorMessage = await result.text();

        return new UnsuccessfulContentResult({
          failureReason: ResultFailureReason.FetchError,
          code: "result-not-ok",
          message: errorMessage,
        });
      }

      const response: ResponseBase<T> = await result.json();
      if (response.success)
        return new SuccessfulContentResult(response.content);

      return this.mapToUnsuccessfulResult(response);
    } catch (e) {
      return new UnsuccessfulContentResult({
        failureReason: ResultFailureReason.ServerError,
        code: "failed-to-fetch",
        message: "Failed to fetch",
      });
    }
  }
}
