import { useClerk } from "@clerk/clerk-react";
import { mdiBookOpenBlankVariant, mdiCommentPlusOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { MessagesSquare } from "lucide-react";
import React, { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { IActivityViewModel } from "../../../application/viewModels/ActivityViewModel";
import { ICheckBaseViewModel } from "../../../application/viewModels/CheckBaseViewModel";
import { OperatorRoutePath } from "../../../data";
import { CommentType } from "../../../infrastructure/api/common/types";
import ListButton from "../../molecules/ListButton";
import UserAvatar from "../../molecules/user-avatar";
import ActivityTimeline from "../ActivityTimeline";

interface ICheckPopupBaseProps {
  activities: IActivityViewModel[];
  instructionId?: string;
  signed: boolean;
  check: ICheckBaseViewModel;
}

export const CheckPopupBase: React.FC<
  PropsWithChildren<ICheckPopupBaseProps>
> = ({ activities, instructionId, signed, check, children }) => {
  const nav = useNavigate();
  const { user } = useClerk();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  return (
    <div className="flex flex-col">
      {/* <div className="px-5 mb-1">
        <span className="text-3xl">Check</span>
      </div>
      <div className="flex items-center px-5 mb-6 justify-between">
        <div>
          <span className="text-muted-foreground text-base font-medium">
            Inrichten 1 / 12
          </span>
        </div>
        <div className="flex gap-x-2">
          <div className="h-8 w-8 flex items-center justify-center rounded-full bg-background">
            <ChevronLeft className="w-5 h-5 text-muted-foreground" />
          </div>
          <div className="h-8 w-8 flex items-center justify-center rounded-full bg-background">
            <ChevronRight className="w-5 h-5 text-muted-foreground" />
          </div>
        </div>
      </div> */}
      <div className="">{children}</div>
      <div className="pt-6 flex-grow">
        <div>
          {instructionId && (
            <div className="px-6 mb-4">
              <div className="px-4 md:px-6 rounded-xl bg-surface-low dark:bg-surface-high cursor-pointer">
                <ListButton
                  title="Instructies bekijken"
                  subTitle="Uitleg over deze check"
                  icon={mdiBookOpenBlankVariant}
                  onClick={() => nav(OperatorRoutePath.CheckInstructions)}
                />
              </div>
            </div>
          )}
          <div className="">
            <div className="mb-3 px-4 md:px-6 flex items-center justify-between w-full">
              <span className="block text-base font-medium 1text-muted-foreground">
                {t("inspectionPage.checkPopup.activities")}
              </span>
              {!signed && (
                <button
                  className="hidden leading-none cursor-pointer w-fit 1whitespace-nowrap h-10 1bg-card text-sm dark:text-violet-300 font-medium text-violet-500 text-center flex items-center rounded-full"
                  onClick={() =>
                    nav(
                      `${OperatorRoutePath.CheckActivityBase}/${
                        CommentType.Info
                      }/?projectId=${searchParams.get(
                        "projectId",
                      )}&check-popup=open`,
                    )
                  }
                >
                  <Icon
                    className="mr-3"
                    path={mdiCommentPlusOutline}
                    size={0.8}
                  />{" "}
                  {t("inspectionPage.checkPopup.addActivity")}
                </button>
              )}
            </div>
            {!signed && (
              <div className="px-6">
                <div
                  className="rounded-xl bg-surface-low dark:bg-surface-high py-4 px-4 cursor-pointer"
                  onClick={() =>
                    nav(
                      `${OperatorRoutePath.CheckActivityBase}/${
                        CommentType.Info
                      }/?projectId=${searchParams.get(
                        "projectId",
                      )}&check-popup=open`,
                    )
                  }
                >
                  <div className="flex items-center mb-2">
                    <UserAvatar id={user?.id} />
                  </div>
                  <span className="text-sm text-muted-foreground/60 w-full rounded-lg h-fit dark:bg-surface-low bg-surface-high block px-3 py-1.5">
                    {t("inspectionPage.checkPopup.addActivity")}
                  </span>
                </div>
              </div>
            )}

            {activities.length === 0 && (
              <div className="md:px-6 px-4 mt-4">
                <div className="bg-surface-low dark:bg-surface-low flex-col 1border flex items-center justify-center border-dashed w-full h-32 md:px-6 px-4 rounded-xl">
                  <MessagesSquare className="w-6 h-6 mb-2 text-muted-foreground/60" />
                  <span className="text-muted-foreground/60 text-sm">
                    {t("inspectionPage.checkPopup.noActivities")}
                  </span>
                </div>
              </div>
            )}

            <div className={"hidden rounded-full mt-2 mb-2"}>
              <div className="px-4 1ml-4 md:px-6 py-21">
                {!signed && (
                  <button
                    className="cursor-pointer w-full h-10 1bg-card text-sm dark:text-violet-300 font-medium text-violet-500 text-center flex items-center justify-center rounded-full"
                    onClick={() =>
                      nav(
                        `${OperatorRoutePath.CheckActivityBase}/${
                          CommentType.Info
                        }/?projectId=${searchParams.get(
                          "projectId",
                        )}&check-popup=open`,
                      )
                    }
                  >
                    <Icon
                      className="mr-3"
                      path={mdiCommentPlusOutline}
                      size={0.8}
                    />{" "}
                    {t("inspectionPage.checkPopup.addActivity")}
                  </button>
                )}
              </div>
            </div>
            {activities.length > 0 && (
              <div className="px-4 md:px-6 pb-20">
                <ActivityTimeline
                  signed={signed}
                  activities={activities}
                  check={check}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
