import { EndpointMethod } from "./EndpointMethod";
import { ResponseBase } from "./types";
import { IContentResult } from "../../../core/results/Result";
import { SuccessfulContentResult } from "../../../core/results/successful/SuccessfulResult";

export class PostFormMethod<T> extends EndpointMethod {

  public async execute(body?: FormData): Promise<IContentResult<T>> {
    const result = await fetch(`${this.path}`, {
      method: "POST",
      body: body,
      headers: {...this.headers}
    });

    if (!result.ok) {
      const errorMessage = await result.text();

      throw Error(errorMessage);
    }

    const response: ResponseBase<T> = await result.json();
    if (response.success)
      return new SuccessfulContentResult(response.content);

    return this.mapToUnsuccessfulResult(response);
  }
}
