import { OriginReference } from "../../../application/viewModels/CheckBaseViewModel";
import { CheckTypes } from "../../../core/domain/entities/checklist/check/CheckBaseEntity";

export enum CommentType {
  Info = 1,
  Rejected = 2,
  Recovered = 3,
}

export enum CheckState {
  Empty = 1,
  Good = 2,
  Neutral = 3,
  Tolerated = 4,
  Bad = 5,
  Recovered = 6,
}

export enum CheckPriority {
  Low = 1,
  Moderate = 2,
  High = 3,
  Critical = 4,
}

export enum ChecklistState {
  Accepted = 1,
  ApprovalRequired = 2,
  RecoveryRequired = 3,
  InComplete = 4,
}

export enum WorkInstructionExampleType {
  Correct = 1,
  Wrong = 2,
}

export interface IChecklistDTO {
  uid: string;
  serialNumber: string;
  title: string;
  accepted: boolean;
  state: ChecklistState;
  groups: ISectionDTO[];
  createdBy: OriginReference | null;
  acceptedBy: OriginReference | null;
}

export interface ICommentDTO {
  uid: string;
  message: string;
  author: OriginReference | null;
  type: CommentType;
  // timeStamp: Date;
  createdAt: string;
  updatedAt: string;
  photos: IPhotoDTO[];
}

export interface ISectionDTO {
  uid: string;
  title: string;
  checks: ICheckDTO[];
}

export interface IPhotoDTO {
  uid: string;
  uri: string;
}

export interface ICheckDTO {
  uid: string;
  description: string;
  priority: CheckPriority;
  comments: ICommentDTO[];
  type: CheckTypes;
  checkState: CheckState;
  locations: {
    id: number;
    name: string;
    description: string;
    workspaceId: 0;
  }[];
  workInstructionId?: string;
  isRequired: boolean;
  lastTouchedBy: OriginReference | null;
  lastTouchedAt: Date | null;
}

export interface IChecklistSearchDTO {
  serialNumber: string;
  percentage: number;
  title: string;
  state: ChecklistState;
}

export interface IWorkInstructionDTO {
  uid: string;
  title: string;
  description: string;
  correctExamples: IWorkInstructionExampleDTO[];
  wrongExamples: IWorkInstructionExampleDTO[];
}

export interface IWorkInstructionExampleDTO {
  uid: string;
  uri: string;
}

export interface IParams {
  [x: string]: string | number | undefined;
}
