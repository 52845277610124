"use client";

import { useOrganization, useOrganizationList } from "@clerk/clerk-react";
import { ChevronsUpDown } from "lucide-react";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
import { Button } from "../ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/context-menu";

export default function OrganizationSwitcherCustom() {
  const { organization, isLoaded } = useOrganization();
  const { organizationList, setActive } = useOrganizationList();

  async function handleOrganizationSwitch(organizationId: string) {
    await setActive?.({ organization: organizationId });

    if (typeof window !== "undefined") {
      window.location.reload();
    }
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <Button
          variant="ghost"
          className="pl-3 py-2 h-fit pr-2 dark:bg-surface-high bg-surface-low font-normal text-primary"
        >
          {!isLoaded && (
            <div className="animate-pulse bg-slate-200 w-20 h-6 rounded-lg"></div>
          )}
          {isLoaded && organization?.name && (
            <Avatar className="w-5 h-5 mr-2">
              <AvatarImage src={organization.imageUrl} alt="@shadcn" />
              <AvatarFallback>{organization.name.charAt(0)}</AvatarFallback>
            </Avatar>
          )}
          {(isLoaded && organization?.name) ?? "Error loading organization"}
          <ChevronsUpDown className="ml-2 h-4 w-4 text-muted-foreground/60" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-52">
        {/* <DropdownMenuLabel className="truncate">Workspaces</DropdownMenuLabel> */}
        {/* <DropdownMenuSeparator /> */}
        {organizationList?.map((o) => (
          <DropdownMenuItem
            key={o.organization.id}
            onClick={() => handleOrganizationSwitch(o.organization.id)}
          >
            {/* <Building className="w-4 h-4 mr-2" /> */}
            <Avatar className="w-5 h-5 mr-2">
              <AvatarImage src={o.organization.imageUrl} alt="@shadcn" />
              <AvatarFallback>{o.organization.name.charAt(0)}</AvatarFallback>
            </Avatar>
            {o.organization.name}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
