import {
  CheckBaseEntity,
  CheckTypes,
  ICheckBaseEntity,
} from "../../../core/domain/entities/checklist/check/CheckBaseEntity";
import {
  CheckChecklistEntity,
  ICheckChecklistEntity,
} from "../../../core/domain/entities/checklist/check/CheckChecklistEntity";
import {
  CheckInputNumberEntity,
  ICheckInputNumberEntity,
} from "../../../core/domain/entities/checklist/check/CheckInputNumberEntity";
import {
  CheckInputSingleLineEntity,
  ICheckInputSingleLineEntity,
} from "../../../core/domain/entities/checklist/check/CheckInputSingleLineEntity";
import {
  CheckOkNotOkEntity,
  ICheckEntityOkNotOk,
} from "../../../core/domain/entities/checklist/check/CheckOkNotOkEntity";
import {
  CheckPhotoEntity,
  ICheckPhotoEntity,
} from "../../../core/domain/entities/checklist/check/CheckPhotoEntity";
import {
  CheckScanEntity,
  ICheckScanEntity,
} from "../../../core/domain/entities/checklist/check/CheckScanEntity";
import {
  CheckSignatureEntity,
  ICheckSignatureEntity,
} from "../../../core/domain/entities/checklist/check/CheckSignatureEntity";
import {
  CheckSingleOptionEntity,
  ICheckSingleOptionEntity,
} from "../../../core/domain/entities/checklist/check/CheckSingleOptionEntity";
import {
  CheckYesNoEntity,
  ICheckYesNoEntity,
} from "../../../core/domain/entities/checklist/check/CheckYesNoEntity";
import { ICheckDTO } from "../common/types";
import { CommentMapper } from "./CommentMapper";

export class CheckMapper {
  public static fromDTO(dto: ICheckDTO): CheckBaseEntity {
    const baseCheck: ICheckBaseEntity = {
      ...dto,
      state: dto.checkState,
      required: dto.isRequired,
      lastTouchedAt: dto.lastTouchedAt ? new Date(dto.lastTouchedAt) : null,
      activities: dto.comments.map((c) => CommentMapper.fromDTO(c)),
    };

    switch (dto.type) {
      case CheckTypes.yesNo:
        return CheckYesNoEntity.existing(baseCheck as ICheckYesNoEntity);
      case CheckTypes.scan:
        return CheckScanEntity.existing(baseCheck as ICheckScanEntity);
      case CheckTypes.checklist:
        return CheckChecklistEntity.existing(
          baseCheck as ICheckChecklistEntity,
        );
      case CheckTypes.inputSingleLine:
        return CheckInputSingleLineEntity.existing(
          baseCheck as ICheckInputSingleLineEntity,
        );
      case CheckTypes.inputNumber:
        return CheckInputNumberEntity.existing(
          baseCheck as ICheckInputNumberEntity,
        );
      case CheckTypes.signature:
        return CheckSignatureEntity.existing(
          baseCheck as ICheckSignatureEntity,
        );
      case CheckTypes.singleOption:
        return CheckSingleOptionEntity.existing(
          baseCheck as ICheckSingleOptionEntity,
        );
      case CheckTypes.photo:
        return CheckPhotoEntity.existing(baseCheck as ICheckPhotoEntity);
      default:
        return CheckOkNotOkEntity.existing(baseCheck as ICheckEntityOkNotOk);
    }
  }
}
