import { ChecklistRepository } from "../../../infrastructure/repositories/ChecklistRepository";
import { IResult } from "../../results/Result";
import { ChecklistEntity } from "../../domain/entities/checklist/ChecklistEntity";

export class JoinChecklistCommand {
  private _checklistRepository: ChecklistRepository = new ChecklistRepository();

  public async handle(
    serial: string,
    updateCallback: (checklistEntity: ChecklistEntity) => void,
  ): Promise<IResult> {
    return await this._checklistRepository.joinChecklist(
      serial,
      updateCallback,
    );
  }
}
