import {
  mdiBarcodeScan,
  mdiCalendar,
  mdiCamera,
  mdiCheckboxMarkedOutline,
  mdiCubeScan,
  mdiFormTextbox,
  mdiNumeric,
  mdiPencil,
  mdiText,
  mdiThumbsUpDownOutline,
} from "@mdi/js";
import React from "react";
import {
  ActivitiesContainer,
  ActivityWrapper,
  CheckElement,
  FinishedIconWrapper,
  UnfinishedIconWrapper,
} from "./styled";

import Icon from "@mdi/react";
import {
  CheckCircle2,
  Circle,
  CircleDashed,
  CircleDot,
  CircleDotDashed,
  MessageCircle,
  MessageSquare,
} from "lucide-react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { IActivityViewModel } from "../../../application/viewModels/ActivityViewModel";
import { ICheckBaseViewModel } from "../../../application/viewModels/CheckBaseViewModel";
import { ICheckChecklistViewModel } from "../../../application/viewModels/CheckChecklistViewModel";
import { ICheckInputNumberViewModel } from "../../../application/viewModels/CheckInputNumberViewModel";
import { ICheckInputSingleLineViewModel } from "../../../application/viewModels/CheckInputSingleLineViewModel";
import { ICheckOkNotOkViewModel } from "../../../application/viewModels/CheckOkNotOkViewModel";
import { ICheckScanViewModel } from "../../../application/viewModels/CheckScanViewModel";
import { ICheckSignatureViewModel } from "../../../application/viewModels/CheckSignatureViewModel";
import { ICheckSingleOptionViewModel } from "../../../application/viewModels/CheckSingleOptionViewModel";
import { ICheckYesNoViewModel } from "../../../application/viewModels/CheckYesNoViewModel";

interface ICheckProps {
  title: string;
  uid: string;
  type: CheckTypes;
  finished: boolean;
  requiredCheck: boolean;
  activities: IActivityViewModel[];
  check: ICheckBaseViewModel;
  onQuickAction?: () => void;
  onClick?: (e: React.MouseEvent<HTMLButtonElement> | undefined) => void;
}

export enum CheckTypes {
  okNotOk = 1,
  inputNumber,
  yesNo,
  inputSingleLine,
  scan,
  checklist,
  singleOption,
  signature = 8,
  photo = 9,
  date = 100,
}

export const checkTypeIconsMap = {
  [CheckTypes.okNotOk]: mdiCheckboxMarkedOutline,
  [CheckTypes.yesNo]: mdiThumbsUpDownOutline,
  [CheckTypes.scan]: mdiBarcodeScan,
  [CheckTypes.checklist]: mdiCubeScan,
  [CheckTypes.inputSingleLine]: mdiFormTextbox,
  [CheckTypes.inputNumber]: mdiNumeric,
  [CheckTypes.singleOption]: mdiText,
  [CheckTypes.signature]: mdiPencil,
  [CheckTypes.date]: mdiCalendar,
  [CheckTypes.photo]: mdiCamera,
};

export const Check: React.FC<ICheckProps> = observer(
  ({ finished, requiredCheck, type, activities, uid, check, ...rest }) => {
    const amountOfActivities = activities.length;
    const { checkUid } = useParams<{ checkUid: string }>();
    const { t } = useTranslation();

    return (
      <CheckElement
        className={`grid-cols-5 relative first:mt-2 group w-full sm:dark:hover:bg-[#323238]/30 rounded-lg 1active:bg-card/20 sm:grid-cols-6  ${
          checkUid === uid
            ? "dark:bg-[#323238]/30 1bg-muted/40 1sm:hover:bg-[#323238]/70 1pl-6  1pl-3 1sm:bg-surface-highest 1border-2 1rounded-l-none 1border-[#cac4d0]"
            : "1border-2 1border-transparent"
        }`}
        id={checkUid === uid ? "active-check" : ""}
        active={checkUid === uid}
        finished={finished}
        onClick={rest.onClick}
      >
        {checkUid === uid && (
          <div className="fade-in-left 1border-l-4 w-full border-[#cac4d0] h-full w-4 flex items-center justify-center absolute top-0 left-[-8px] 1rounded-sm">
            <img
              className="opacity-30 "
              style={{
                position: "absolute",
                zIndex: 99999,
                width: 12,
                // left: 24,
                // left: 16,
                left: 28,
                top: 17,
                transform: "rotate(90deg)",
              }}
              src="/Polygon 2.svg"
            />
          </div>
        )}
        <div
          className={`duration-200 ease-out col-span-4 sm:col-span-3 md:col-span-3 lg:col-span-4 py-3.5 flex items-center justify-between ${
            checkUid === uid ? "pr-4 pl-[42px]" : "px-4"
          }`}
        >
          <div className="mr-3 h-full self-start">
            {!check.finished && checkUid === uid && !check.required && (
              <div className="">
                <CircleDashed className="w-4 h-4 animate-[spin_7s_linear_infinite] text-muted-foreground" />
              </div>
            )}
            {!check.finished && checkUid === uid && check.required && (
              <div className="">
                <CircleDotDashed className="w-4 h-4 animate-[spin_7s_linear_infinite] text-muted-foreground" />
              </div>
            )}

            {check.finished && (
              <FinishedIconWrapper className="text-emerald-600 dark:text-emerald-400  ">
                <CheckCircle2 className="w-4 h-4 text-green-400" />
              </FinishedIconWrapper>
            )}

            {!check.finished && requiredCheck && checkUid !== uid && (
              <UnfinishedIconWrapper className="text-muted-foreground">
                <CircleDot className="w-4 h-4 text-muted-foreground" />
              </UnfinishedIconWrapper>
            )}

            {!check.finished && !requiredCheck && checkUid !== uid && (
              <UnfinishedIconWrapper className="text-muted-foreground">
                <Circle className="w-4 h-4 text-muted-foreground" />
              </UnfinishedIconWrapper>
            )}
          </div>
          <div className="w-full text-left">
            <span
              className={`leading-none block text-sm font-semibold text-muted-foreground truncate sm:pr-8 sm:w-full overflow-hidden ${
                finished && checkUid !== uid
                  ? "opacity-50 1group-active:opacity-100"
                  : ""
              }
              ${checkUid === uid ? "text-primary" : ""}
              `}
              style={{ wordBreak: "break-word" }}
            >
              {rest.title.includes(":date:")
                ? rest.title.split(":date:")[1]
                : rest.title}
            </span>
            <div className="opacity-50">
              {activities.length > 0 && (
                <ActivitiesContainer>
                  <ActivityWrapper className="mt-2 text-muted-foreground font-medium">
                    <MessageSquare className="w-4 h-4" />
                    <span className="text-xs">
                      {check.activities.length}{" "}
                      {check.activities.length === 1
                        ? "activiteit"
                        : "activiteiten"}
                    </span>
                  </ActivityWrapper>
                </ActivitiesContainer>
              )}
            </div>
          </div>
        </div>
        <div className="col-span-2 sm:col-span-2 md:col-span-2 lg:col-span-1 hidden items-center h-full sm:flex">
          {check.type === CheckTypes.okNotOk &&
            (check as ICheckOkNotOkViewModel).state !== 1 && (
              <div className="1check-answer dark:bg-surface-highest bg-surface-lowest h-fit flex items-center py-1.5 rounded-md px-2 w-fit 1mt-2.5">
                <span className="font-semibold text-xs text-muted-foreground font-mono leading-none">
                  {(check as ICheckOkNotOkViewModel).state === 2 &&
                    t("inspectionPage.accepted")}
                  {(check as ICheckOkNotOkViewModel).state === 5 &&
                    t("inspectionPage.rejected")}
                  {(check as ICheckOkNotOkViewModel).state === 6 &&
                    t("inspectionPage.recovered")}
                </span>
              </div>
            )}

          {check.type === CheckTypes.inputNumber &&
            (check as ICheckInputNumberViewModel).number !== null && (
              <div className="1check-answer dark:bg-surface-highest bg-surface-lowest h-fit flex items-center py-1.5 rounded-md px-2 w-fit 1mt-2.5">
                <span className="font-semibold text-xs text-muted-foreground font-mono leading-none">
                  {(check as ICheckInputNumberViewModel).number}
                </span>
              </div>
            )}

          {check.type === CheckTypes.yesNo &&
            (check as ICheckYesNoViewModel).answer !== null && (
              <div className="flex gap-x-1.5">
                <div className="1check-answer dark:bg-surface-highest bg-surface-lowest h-fit flex items-center py-1.5 rounded-md px-2 w-fit 1mt-2.5">
                  <span className="font-semibold max-w-[164px] overflow-hidden truncate text-xs text-muted-foreground font-mono leading-none">
                    {`${
                      (check as ICheckYesNoViewModel).answer
                        ? t("inspectionPage.yes")
                        : t("inspectionPage.no")
                    }`}
                  </span>
                </div>
                {(check as ICheckYesNoViewModel).comment !== null && (
                  <div className="1check-answer dark:bg-surface-highest bg-surface-lowest h-fit flex items-center py-1.5 rounded-md px-2 w-fit 1mt-2.5">
                    <MessageCircle className="w-3 h-3 mr-1 text-muted-foreground" />
                    <span className="font-semibold max-w-[164px] overflow-hidden truncate text-xs text-muted-foreground font-mono leading-none">
                      {`${(check as ICheckYesNoViewModel).comment?.value}`}
                    </span>
                  </div>
                )}
              </div>
            )}

          {check.type === CheckTypes.inputSingleLine &&
            (check as ICheckInputSingleLineViewModel).text !== null && (
              <div className="1check-answer dark:bg-surface-highest bg-surface-lowest h-fit flex items-center py-1.5 rounded-md px-2 w-fit 1mt-2.5">
                <span className="font-semibold max-w-[164px] overflow-hidden truncate text-xs text-muted-foreground font-mono leading-none">
                  {(check as ICheckInputSingleLineViewModel).text}
                </span>
              </div>
            )}

          {check.type === CheckTypes.scan &&
            (check as ICheckScanViewModel).barcode !== null && (
              <div className="1check-answer dark:bg-surface-highest bg-surface-lowest h-fit flex items-center py-1.5 rounded-md px-2 w-fit 1mt-2.5">
                <span className="font-semibold max-w-[164px] overflow-hidden truncate text-xs text-muted-foreground font-mono leading-none">
                  {(check as ICheckScanViewModel).barcode}
                </span>
              </div>
            )}

          {check.type === CheckTypes.checklist &&
            (check as ICheckChecklistViewModel).serialNumber !== null && (
              <div className="1check-answer dark:bg-surface-highest bg-surface-lowest h-fit flex items-center py-1.5 rounded-md px-2 w-fit 1mt-2.5">
                <span className="font-semibold max-w-[164px] overflow-hidden truncate text-xs text-muted-foreground font-mono leading-none">
                  {(check as ICheckChecklistViewModel).serialNumber}
                </span>
              </div>
            )}

          {check.type === CheckTypes.singleOption &&
            (check as ICheckSingleOptionViewModel).selectedChoice !== null && (
              <div className="1check-answer dark:bg-surface-highest bg-surface-lowest h-fit flex items-center py-1.5 rounded-md px-2 w-fit 1mt-2.5">
                <span className="font-semibold max-w-[164px] overflow-hidden truncate text-xs text-muted-foreground font-mono leading-none">
                  {(check as ICheckSingleOptionViewModel).selectedChoice}
                </span>
              </div>
            )}
          {check.type === CheckTypes.signature &&
            (check as ICheckSignatureViewModel).signed && (
              <div className="1check-answer dark:bg-surface-highest bg-surface-lowest h-fit flex items-center py-1.5 rounded-md px-2 w-fit 1mt-2.5">
                <span className="font-semibold max-w-[164px] overflow-hidden truncate text-xs text-muted-foreground font-mono leading-none">
                  {t("inspectionPage.signed")}
                </span>
              </div>
            )}
        </div>
        <div className="ml-auto mr-4 py-3.5">
          <Icon
            path={
              checkTypeIconsMap[
                check.title.includes(":date:") ? CheckTypes.date : type
              ]
            }
            className="opacity-30"
            size={0.8}
          />
        </div>
      </CheckElement>
    );
  },
);
