import {
  ClerkLoaded,
  ClerkProvider,
  RedirectToSignIn,
  SignedIn,
  SignedOut,
} from "@clerk/clerk-react";
import { nlNL } from "@clerk/localizations";
import { Outlet, Route, Routes, useNavigate } from "react-router-dom";
import { OperatorRoutePath } from "../../../data";
import { SettingsManager } from "../../context/SettingsManager";
import QueryClientContainer from "../../context/query-client";
import { AddActivityPage } from "../../pages/AddActivityPage/AddActivityPage";
import { AddChecklistToProjectPage } from "../../pages/AddChecklistToProjectPage/AddChecklistToProjectPage";
import { CheckPage } from "../../pages/CheckPage/CheckPage";
import { ChecklistPage } from "../../pages/ChecklistPage/ChecklistPage";
import { InstructionPage } from "../../pages/InstructionPage/InstructionPage";
import { ProductChecklistPage } from "../../pages/ProductChecklistPage/ProductChecklistPage";
import ProductChecklistSelectPage from "../../pages/ProductChecklistSelectPage";
import ProjectChecklistPage from "../../pages/ProjectChecklistPage";
import { ProjectPage } from "../../pages/ProjectPage/ProjectPage";
import { SettingsPage } from "../../pages/SettingsPage/SettingsPage";
import SignInPage from "../../pages/SignInPage";
import SignUpPage from "../../pages/SignUpPage";
import { StartProjectPage } from "../../pages/StartProjectPage/StartProjectPage";
import ZebraScannerTestPage from "../../pages/ZebraScannerTestPage";

if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key");
}

const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;
const clerkSignInUrl = process.env.REACT_APP_CLERK_SIGN_IN_URL!;
const clerkSignUpUrl = process.env.REACT_APP_CLERK_SIGN_UP_URL!;

export default function ClerkProviderWithRoutes() {
  const navigate = useNavigate();

  return (
    <ClerkProvider
      localization={{
        ...nlNL,
        formFieldInputPlaceholder__emailAddress: "voorbeeld@checklab.nl",
        dividerText: "of met je e-mailadres",
        signIn: {
          ...nlNL.signIn,
          start: {
            ...nlNL.signIn?.start,
            title: "Welkom terug,",
            subtitle: "Log in om door te gaan naar Checklab",
            actionText: "Heb je nog geen account?",
          },
        },
      }}
      publishableKey={clerkPubKey}
      navigate={(to) => navigate(to)}
      signInUrl={clerkSignInUrl}
      signUpUrl={clerkSignUpUrl}
    >
      <QueryClientContainer>
        <SettingsManager>
          <Routes>
            <Route
              path={"/sign-in"}
              element={
                <>
                  <ClerkLoaded>
                    <SignInPage />
                  </ClerkLoaded>
                </>
              }
            />
            <Route
              path={"/sign-up"}
              element={
                <>
                  <ClerkLoaded>
                    <SignUpPage />
                  </ClerkLoaded>
                </>
              }
            />
            <Route
              path={OperatorRoutePath.ProductChecklist}
              element={
                <>
                  <ClerkLoaded>
                    <SignedIn>
                      <ProductChecklistPage />
                    </SignedIn>
                    <SignedOut>
                      <>
                        <RedirectToSignIn />
                      </>
                    </SignedOut>
                  </ClerkLoaded>
                </>
              }
            />
            <Route
              path={OperatorRoutePath.ProductChecklistSelect}
              element={
                <>
                  <ClerkLoaded>
                    <SignedIn>
                      <ProductChecklistSelectPage />
                    </SignedIn>
                    <SignedOut>
                      <>
                        <RedirectToSignIn />
                      </>
                    </SignedOut>
                  </ClerkLoaded>
                </>
              }
            />
            <Route
              path={OperatorRoutePath.AddProductChecklistToProject}
              element={
                <>
                  <ClerkLoaded>
                    <SignedIn>
                      <ProductChecklistPage />{" "}
                    </SignedIn>
                    <SignedOut>
                      <>
                        <RedirectToSignIn />
                      </>
                    </SignedOut>
                  </ClerkLoaded>
                </>
              }
            />
            <Route
              path={OperatorRoutePath.AddProductChecklistToProjectPL}
              element={
                <>
                  <ClerkLoaded>
                    <SignedIn>
                      <ProductChecklistPage />
                    </SignedIn>
                    <SignedOut>
                      <>
                        <RedirectToSignIn />
                      </>
                    </SignedOut>
                  </ClerkLoaded>
                </>
              }
            />
            <Route
              path={OperatorRoutePath.Project}
              element={
                <>
                  <ClerkLoaded>
                    <SignedIn>
                      <ProjectPage />
                    </SignedIn>
                    <SignedOut>
                      <>
                        <RedirectToSignIn />
                      </>
                    </SignedOut>
                  </ClerkLoaded>
                </>
              }
            />
            <Route
              // path={OperatorRoutePath.StartProject}
              path={"/"}
              element={
                <>
                  <ClerkLoaded>
                    <SignedIn>
                      <StartProjectPage />
                    </SignedIn>
                    <SignedOut>
                      <>
                        <RedirectToSignIn />
                      </>
                    </SignedOut>
                  </ClerkLoaded>
                </>
              }
            />
            <Route
              path={"datawedge-scanner-debug"}
              element={
                <>
                  <ClerkLoaded>
                    <SignedIn>
                      <ZebraScannerTestPage />
                    </SignedIn>
                    <SignedOut>
                      <>
                        <RedirectToSignIn />
                      </>
                    </SignedOut>
                  </ClerkLoaded>
                </>
              }
            />
            <Route
              path={OperatorRoutePath.AddChecklistToProject}
              element={
                <>
                  <ClerkLoaded>
                    <SignedIn>
                      <AddChecklistToProjectPage />
                    </SignedIn>
                    <SignedOut>
                      <>
                        <RedirectToSignIn />
                      </>
                    </SignedOut>
                  </ClerkLoaded>
                </>
              }
            />
            <Route
              path={OperatorRoutePath.AddProjectChecklistToProject}
              element={
                <>
                  <ClerkLoaded>
                    <SignedIn>
                      <ProjectChecklistPage />
                    </SignedIn>
                    <SignedOut>
                      <>
                        <RedirectToSignIn />
                      </>
                    </SignedOut>
                  </ClerkLoaded>
                </>
              }
            />
            <Route
              path={OperatorRoutePath.Settings}
              element={
                <>
                  <ClerkLoaded>
                    <SignedIn>
                      <SettingsPage />
                    </SignedIn>
                    <SignedOut>
                      <>
                        <RedirectToSignIn />
                      </>
                    </SignedOut>
                  </ClerkLoaded>
                </>
              }
            />

            <Route
              path={OperatorRoutePath.Checklist}
              element={
                <ClerkLoaded>
                  <SignedIn>
                    <ChecklistPage />
                  </SignedIn>
                  <SignedOut>
                    <>
                      <RedirectToSignIn />
                    </>
                  </SignedOut>
                </ClerkLoaded>
              }
            >
              <Route path={OperatorRoutePath.Check} element={<Outlet />}>
                <Route index element={<CheckPage />} />
                <Route
                  path={OperatorRoutePath.CheckActivity}
                  element={<AddActivityPage />}
                />
                <Route
                  path={OperatorRoutePath.CheckInstructions}
                  element={<InstructionPage />}
                />
              </Route>
            </Route>
          </Routes>
        </SettingsManager>
      </QueryClientContainer>
    </ClerkProvider>
  );
}
