import axios from "axios";
import qs from "qs";

export const instance = axios.create({
  timeout: 20000,
  headers: { "X-Custom-Header": "foobar" },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
  transformResponse: [
    function (data) {
      let res = undefined;

      try {
        res = JSON.parse(data).content;

        if (res === undefined) {
          res = JSON.parse(data);
        }
      } catch (e) {
        res = JSON.parse(data);
      }

      return res;
    },
  ],
});
