import {
  mdiBarcode,
  mdiDebugStepOver,
  mdiFileTreeOutline,
  mdiFormatListGroup,
} from "@mdi/js";
import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { templateLocationsHooks } from "../../../api";
import Grid from "../../atoms/Grid";
import { SettingsContext } from "../../context/SettingsManager";
import ListButton from "../../molecules/ListButton";
import OrganizationSwitcherCustom from "../../molecules/organization-switcher";
import { Input } from "../../ui/input";
import { Switch } from "../../ui/switch";
// import Switch from "../../molecules/Switch";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../../molecules/language-switcher";
import { Button } from "../../ui/button";

interface ISettingsTemplateProps {
  null?: null;
}

interface ILocationSate {
  previousRoute: string;
}

export const SettingsTemplate: React.FC<ISettingsTemplateProps> = () => {
  const location = useLocation();
  const {
    toggleDarkMode,
    toggleGroupChecks,
    groupChecks,
    defaultOpenSections,
    darkMode,
    logout,
    openNextCheck,
    toggleOpenNextCheck,
    toggleDefaultOpenSections,
    toggleIsMemor10,
    isMemor10,
    toggleIsZebraScanner,
    isZebraScanner,
    activeLocationIds,
    updateActiveLocationIds,
    barcodeRegex,
    updateBarcodeRegex,
    updateBarcodePrefix,
    updateBarcodeSuffix,
    barcodePrefix,
    barcodeSuffix,
  } = useContext(SettingsContext);
  const { previousRoute } = (location.state as ILocationSate) ?? {};
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { data: locations } = templateLocationsHooks.useGet("/locations", {
    queries: { page: 1, size: 100 },
  });

  return (
    <div className="pl-2 sm:pl-0 pr-2 py-2 h-fit pb-12">
      <div
        className="bg-surface-container rounded-xl overflow-scroll pb-4 md:h-[calc(100vh-16px)] h-[calc(100vh-77px)]"
        // style={{ height: "calc(100vh - 16px)" }}
      >
        <Grid>
          <div className=" pt-4 pb-0">
            <span className="text-3xl">{t("settingsPage.title")}</span>
          </div>

          <div className="border-2 border-dashed border-yellow-500/20 rounded-lg p-3">
            <div className="flex justify-between items-center w-full mb-2">
              <span className="text-sm block text-white">
                <span className="mr-1">{`🚧`}</span>{" "}
                {t("settingsPage.developerSettings")}
              </span>
            </div>
            <div>
              <Button
                variant={"outline"}
                size={"sm"}
                className="text-yellow-500 border-yellow-500 text-xs h-fit py-1.5 px-2"
                onClick={() => navigate("/datawedge-scanner-debug")}
              >
                {t("settingsPage.openDataWedgeDebugger")}
              </Button>
            </div>
          </div>
          {/* <ListButton
            title="Donker thema"
            subTitle="Verander het kleuren thema"
            icon={mdiMoonWaxingCrescent}
            onClick={toggleDarkMode}
            onQuickAction={toggleDarkMode}
            quickActionElement={
              <Switch
                checked={darkMode}
                onChange={() => {
                  return;
                }}
              />
            }
          /> */}
          <ListButton
            title={t("settingsPage.groupChecks")}
            subTitle={t("settingsPage.groupChecksDescription")}
            icon={mdiFormatListGroup}
            onClick={toggleGroupChecks}
            onQuickAction={toggleGroupChecks}
            quickActionElement={
              <Switch
                checked={groupChecks}
                onChange={() => {
                  return;
                }}
              />
            }
          />
          <ListButton
            title={t("settingsPage.nextCheck")}
            subTitle={t("settingsPage.nextCheckDescription")}
            icon={mdiDebugStepOver}
            onClick={toggleOpenNextCheck}
            onQuickAction={toggleOpenNextCheck}
            quickActionElement={
              <Switch
                checked={openNextCheck}
                onChange={() => {
                  return;
                }}
              />
            }
          />
          <ListButton
            title={t("settingsPage.expandGroups")}
            subTitle={t("settingsPage.expandGroupsDescription")}
            icon={mdiFileTreeOutline}
            onClick={toggleDefaultOpenSections}
            onQuickAction={toggleDefaultOpenSections}
            quickActionElement={
              <Switch
                checked={defaultOpenSections}
                onChange={() => {
                  return;
                }}
              />
            }
          />
          <ListButton
            title={t("settingsPage.deviceIsMemor10Scanner")}
            subTitle={t("settingsPage.deviceIsMemor10ScannerDescription")}
            icon={mdiBarcode}
            onClick={toggleIsMemor10}
            onQuickAction={toggleIsMemor10}
            quickActionElement={
              <Switch
                checked={isMemor10}
                onChange={() => {
                  return;
                }}
              />
            }
          />
          <ListButton
            title={t("settingsPage.deviceIsDataWedgeScanner")}
            subTitle={t("settingsPage.deviceIsDataWedgeScannerDescription")}
            icon={mdiBarcode}
            onClick={toggleIsZebraScanner}
            onQuickAction={toggleIsZebraScanner}
            quickActionElement={
              <Switch
                checked={isZebraScanner}
                onChange={() => {
                  return;
                }}
              />
            }
          />

          <div>
            <span className="text-lg mb-3 block">Datawedge prefix</span>
            <Input
              value={barcodePrefix}
              onChange={(e) => {
                updateBarcodePrefix(e.target.value);
              }}
            />
          </div>

          <div>
            <span className="text-lg mb-3 block">Datawedge suffix</span>
            <Input
              value={barcodeSuffix}
              onChange={(e) => updateBarcodeSuffix(e.target.value)}
            />
          </div>

          <div>
            <span className="text-lg mb-3 block">Barcode Regex</span>
            <Input
              value={barcodeRegex}
              onChange={(e) => updateBarcodeRegex(e.target.value)}
            />
          </div>

          <div>
            <span className="text-lg mb-3 block">
              {t("settingsPage.activeLocations")}
            </span>
            <div>
              {locations?.results?.map((location) => (
                <div
                  key={location.id}
                  className="border flex items-center rounded-lg w-full px-3 py-2 mb-1.5"
                >
                  <div>
                    <span className="block text-sm font-semibold">
                      {location.name}
                    </span>
                    <span className="block text-sm text-muted-foreground">
                      {location.description}
                    </span>
                  </div>
                  <Switch
                    className="ml-auto"
                    checked={activeLocationIds.includes(location.id ?? 0)}
                    onCheckedChange={() => {
                      if (activeLocationIds.includes(location.id ?? 0)) {
                        updateActiveLocationIds([
                          ...activeLocationIds.filter((a) => a !== location.id),
                        ]);
                      } else {
                        updateActiveLocationIds([
                          ...activeLocationIds,
                          location.id ?? 0,
                        ]);
                      }
                    }}
                  />
                </div>
              ))}
            </div>
          </div>

          <div>
            <span className="text-lg mb-3 block">Workspace</span>
            <div>
              <OrganizationSwitcherCustom />
            </div>
          </div>

          <div>
            <span className="text-lg mb-3 block">User Language</span>
            <div>
              <LanguageSwitcher />
            </div>
          </div>
        </Grid>
      </div>
    </div>
  );
};
