import { makeObservable, observable, runInAction } from "mobx";
import {
  CheckYesNoEntity,
  YesNoCheckComment,
} from "../../core/domain/entities/checklist/check/CheckYesNoEntity";
import { CheckBaseViewModel, ICheckBaseViewModel } from "./CheckBaseViewModel";

export interface ICheckYesNoViewModel extends ICheckBaseViewModel {
  answer: boolean | null;
  commentRequiredOnNo: boolean;
  commentRequiredOnYes: boolean;
  comment: YesNoCheckComment | null;
  commentOnNoTitle: string | null;
  commentOnYesTitle: string | null;

  yes: (comment?: string) => Promise<void>;
  no: (comment?: string) => Promise<void>;
  reset: () => Promise<void>;
}

export class CheckYesNoViewModel
  extends CheckBaseViewModel
  implements ICheckYesNoViewModel
{
  public answer: boolean | null = null;
  public commentRequiredOnNo: boolean = false;
  public commentRequiredOnYes: boolean = false;
  public comment: YesNoCheckComment | null = null;
  public commentOnNoTitle: string | null = null;
  public commentOnYesTitle: string | null = null;

  constructor(check: CheckYesNoEntity, checklistUid: string, groupUid: string) {
    super(check, checklistUid, groupUid);

    this.answer = check.answer;
    this.commentRequiredOnNo = check.commentRequiredOnNo;
    this.commentRequiredOnYes = check.commentRequiredOnYes;
    this.comment = check.comment;
    this.commentOnYesTitle = check.commentOnYesTitle;
    this.commentOnNoTitle = check.commentOnNoTitle;

    this.finished = this.isFinished();

    makeObservable(this, {
      answer: observable,
    });
  }

  public async yes(comment?: string): Promise<void> {
    runInAction(() => {
      this.answer = true;
      if (comment) {
        this.comment = {
          value: comment,
          createdAt: new Date().toString(),
          author: "test-author",
        };
      }
      this.finished = true;

      //@ts-ignore
      const userId = window.Clerk.user.id;

      this.lastTouchedBy = {
        value: userId,
        type: 0,
      };
    });
  }

  public async no(comment?: string): Promise<void> {
    runInAction(() => {
      this.answer = false;
      if (comment) {
        this.comment = {
          value: comment,
          createdAt: new Date().toString(),
          author: "test-author",
        };
      }
      this.finished = true;

      //@ts-ignore
      const userId = window.Clerk.user.id;

      this.lastTouchedBy = {
        value: userId,
        type: 0,
      };
    });
  }

  public async reset(): Promise<void> {
    runInAction(() => {
      this.answer = null;
      this.comment = null;
      this.finished = false;
      this.lastTouchedBy = null;
    });
  }
}
