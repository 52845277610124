import { Hash } from "lucide-react";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ICheckInputNumberViewModel } from "../../../application/viewModels/CheckInputNumberViewModel";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../components/ui/dialog";
import { CheckState } from "../../../infrastructure/api/common/types";
import { Input } from "../../ui/input";
import CheckStateCard, {
  ActionContainer,
  AnswerBadge,
  AnswerContainer,
  PrimaryActionButton,
} from "./CheckStateCard";
import CheckPopupBase from "./index";
interface ICheckPopupInputNumberProps {
  check: ICheckInputNumberViewModel;
  onFinish: () => void;
  title: string;
  signed: boolean;
  sectionTitle: string;
}

export const CheckPopupInputNumber = observer(
  ({
    check,
    onFinish,
    title,
    signed,
    sectionTitle,
  }: ICheckPopupInputNumberProps): JSX.Element => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [value, setValue] = useState<number | undefined>(undefined);
    const { t } = useTranslation();

    const handleSubmit = async (value: number) => {
      setDialogOpen(false);

      await check.store(value);

      if (check.finished) {
        onFinish();
      }
    };

    const handleReset = () => {
      check.reset();
    };

    return (
      <CheckPopupBase
        activities={check.activities}
        instructionId={check.instructionId}
        signed={signed}
        check={check}
      >
        <CheckStateCard
          signed={signed}
          check={check}
          sectionTitle={sectionTitle}
        >
          {check.number !== null && (
            <AnswerContainer>
              {check.state === CheckState.Bad && check.number !== null ? (
                <AnswerBadge
                  lastTouchedBy={check.lastTouchedBy}
                  lastTouchedAt={check.lastTouchedAt}
                  checklistAccepted={signed}
                  deleteLabel="Opnieuw invoeren"
                  onReset={handleReset}
                >
                  <Hash className="w-5 h-5 mr-2" />({check.number}){" "}
                  {t("inspectionPage.checkPopup.numberCheck.invalidInput")}
                </AnswerBadge>
              ) : (
                <AnswerBadge
                  lastTouchedBy={check.lastTouchedBy}
                  lastTouchedAt={check.lastTouchedAt}
                  checklistAccepted={signed}
                  onReset={handleReset}
                >
                  <Hash className="w-5 h-5 mr-2" />
                  {check.number}
                </AnswerBadge>
              )}
            </AnswerContainer>
          )}

          <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
            <DialogContent className="sm:max-w-[425px]">
              <DialogHeader>
                <DialogTitle>
                  {t("inspectionPage.checkPopup.numberCheck.numberButton")}
                </DialogTitle>
                <DialogDescription>{check.title}</DialogDescription>
              </DialogHeader>
              <div className="grid gap-4 py-4 w-full">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();

                    if (value === undefined) return;

                    handleSubmit(value);

                    setValue(undefined);
                    setDialogOpen(false);
                  }}
                >
                  <Input
                    autoFocus
                    className="w-full"
                    placeholder={t(
                      "inspectionPage.checkPopup.numberCheck.numberButton",
                    )}
                    type="number"
                    value={Number(value).toString()}
                    onChange={(e) => setValue(Number(e.target.value))}
                    step={"any"}
                  />
                  <div>
                    <button
                      disabled={value === undefined}
                      className={`mt-3 text-sm border h-10 text-primary rounded-md w-full ${
                        !value ? "opacity-50" : ""
                      }`}
                      type="submit"
                    >
                      {t("inspectionPage.checkPopup.singleLine.saveButton")}
                    </button>
                  </div>
                </form>
              </div>
              <DialogFooter></DialogFooter>
            </DialogContent>

            {((!check.finished && !check.loading) ||
              (check.loading && check.number === null)) &&
              !signed &&
              check.state !== CheckState.Bad && (
                <DialogTrigger asChild>
                  <ActionContainer>
                    <PrimaryActionButton
                      disabled={check.loading}
                      onClick={() => {
                        setDialogOpen(true);
                      }}
                    >
                      {title}
                    </PrimaryActionButton>
                  </ActionContainer>
                </DialogTrigger>
              )}
          </Dialog>
        </CheckStateCard>
      </CheckPopupBase>
    );
  },
);
