import { mdiAlertOutline, mdiComment, mdiRefresh } from "@mdi/js";
import Icon from "@mdi/react";
import { Edit, MoreVertical, Trash2 } from "lucide-react";
import { useState } from "react";
import { OriginReference } from "../../../application/viewModels/CheckBaseViewModel";
import { PhotoViewModel } from "../../../application/viewModels/PhotoViewModel";
import { CommentType } from "../../../infrastructure/api/common/types";
import TypographyBodyMedium from "../../atoms/TypographyBodyMedium";
import { Button as ButtonShadcnUi } from "../../ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../ui/dropdown-menu";
import ImagePopup from "../ImagePopup";
import { ResponsiveImage } from "../ImagePopup/styled";
import RelativeTimestamp from "../RelativeTimestamp";
import UserAvatar from "../user-avatar";
import {
  ActivityElement,
  ActivitySequenceElement,
  ActivitySequenceLineElement,
  ActivitySequenceLineElementSmall,
  ThumbnailContainerElement,
  ThumbnailImageElement,
} from "./styled";

interface IActivityProps {
  title: string;
  text: string;
  first?: boolean;
  signed: boolean;
  timestamp: Date;
  type: CommentType;
  photos: PhotoViewModel[];
  author: OriginReference | null;
  onDelete: () => void;
  onEdit: (value: string) => void;
}

export const Activity = ({
  first,
  title,
  text,
  timestamp,
  type,
  photos,
  onDelete,
  onEdit,
  author,
  signed,
}: IActivityProps): JSX.Element => {
  const [photoPopup, setPhotoPopup] = useState("");

  const handlePhotoClick = (uri: string) => {
    setPhotoPopup(uri);
  };

  const handleEdit = () => {
    const val = prompt("Activiteit wijzigen");

    if (val) {
      onEdit(val);
    }
  };

  const handleDelete = () => {
    if (confirm("Weet je zeker dat je deze comment wilt verwijderen?")) {
      onDelete();
    }
  };

  return (
    <ActivityElement>
      <ActivitySequenceElement className="hidden grid1">
        {type === CommentType.Info && (
          <div className="bg-card p-1.5 rounded-full">
            <Icon size={0.7} path={mdiComment} />
          </div>
        )}
        {type === CommentType.Rejected && (
          <div className="outline outline-red-600 bg-card p-1.5 rounded-full">
            <Icon
              size={0.7}
              className="text-muted-foreground"
              path={mdiAlertOutline}
            />
          </div>
        )}
        {type === CommentType.Recovered && (
          <div className="outline outline-yellow-600 bg-card p-1.5 rounded-full">
            <Icon
              className="text-muted-foreground"
              size={0.7}
              path={mdiRefresh}
            />
          </div>
        )}
        <ActivitySequenceLineElement className="bg-border" />
      </ActivitySequenceElement>

      {!first && (
        <ActivitySequenceLineElementSmall className="ml-6 bg-border" />
      )}

      <div
        className={`dark:bg-surface-container border bg-surface-low rounded-lg overflow-hidden ${
          first ? "1mt-8 mt-4" : ""
        }`}
      >
        <div className="border-b pl-4 pr-2 py-3 space-x-1.5 flex items-center justify-between 1border-b bg-surface-low dark:bg-surface-high">
          <div className="flex items-center gap-x-2">
            {type === CommentType.Info && (
              <Icon
                className="text-muted-foreground"
                size={0.7}
                path={mdiComment}
              />
            )}
            {type === CommentType.Rejected && (
              <Icon
                size={0.7}
                className="text-red-500"
                path={mdiAlertOutline}
              />
            )}
            {type === CommentType.Recovered && (
              <Icon className="text-yellow-500" size={0.7} path={mdiRefresh} />
            )}
            <span className="text-sm font-medium text-muted-foreground">
              {type === CommentType.Info && "Activiteit"}
              {type === CommentType.Recovered && "Hersteld"}
              {type === CommentType.Rejected && "Afgekeurd"}
            </span>
            <div className="bg-muted-foreground/60 w-1 h-1 rounded-full"></div>
            <span className="text-xs text-muted-foreground">
              <RelativeTimestamp timestamp={timestamp} />
            </span>
          </div>

          <div className="flex items-center gap-x-1">
            <div className="flex items-center">
              <div className="ml-2">
                <UserAvatar left id={author?.value} />
              </div>
            </div>
            {!signed && (
              <DropdownMenu>
                <DropdownMenuContent className="w-56 bg-background">
                  <DropdownMenuItem onClick={handleEdit}>
                    <Edit className="mr-2 h-4 w-4" />
                    <span>Wijzigen</span>
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    className="text-red-400"
                    onClick={handleDelete}
                  >
                    <Trash2 className="mr-2 h-4 w-4" />
                    <span>Verwijderen</span>
                  </DropdownMenuItem>
                </DropdownMenuContent>
                <DropdownMenuTrigger className="" asChild>
                  <ButtonShadcnUi
                    variant={"ghost"}
                    className="w-fit h-fit rounded-full aspect-square 1bg-card 1border px-1 py-1"
                  >
                    <MoreVertical className="w-4 h-4 text-primary" />
                  </ButtonShadcnUi>
                </DropdownMenuTrigger>
              </DropdownMenu>
            )}
          </div>
        </div>
        <div className="px-4 pb-4 pt-4 space-y-4">
          <TypographyBodyMedium>{text}</TypographyBodyMedium>
          {photos.length > 0 && (
            <ThumbnailContainerElement>
              {photos.map((p) => (
                <ThumbnailImageElement
                  onClick={() => handlePhotoClick(p.photoUri)}
                  key={p.photoUri}
                  src={p.photoUri}
                />
              ))}
            </ThumbnailContainerElement>
          )}
        </div>
      </div>
      <ImagePopup
        onClose={() => {
          setPhotoPopup("");
        }}
        open={!!photoPopup}
      >
        <ResponsiveImage src={photoPopup} />
      </ImagePopup>
    </ActivityElement>
  );
};
