import { OriginReference } from "../../../../application/viewModels/CheckBaseViewModel";
import { ChecklistState } from "../../../../infrastructure/api/common/types";
import { Entity } from "../Entity";
import { SectionEntity } from "./SectionEntity";

export class ChecklistEntity extends Entity {
  public readonly serialNumber: string;
  public readonly title: string;
  public readonly signed: boolean;
  public readonly state: ChecklistState;
  public readonly groups: SectionEntity[];
  public readonly createdBy: OriginReference | null = null;
  public readonly acceptedBy: OriginReference | null = null;

  protected constructor(
    uid: string,
    serialNumber: string,
    title: string,
    signed: boolean,
    state: ChecklistState,
    groups: SectionEntity[],
    createdBy: OriginReference | null = null,
    acceptedBy: OriginReference | null = null,
  ) {
    super(uid);

    this.serialNumber = serialNumber;
    this.title = title;
    this.signed = signed;
    this.state = state;
    this.groups = groups;
    this.createdBy = createdBy;
    this.acceptedBy = acceptedBy;
  }

  public static new(
    serialNumber: string,
    title: string,
    signed: boolean,
    state: ChecklistState,
    groups: SectionEntity[],
    createdBy: OriginReference | null = null,
    acceptedBy: OriginReference | null = null,
  ): ChecklistEntity {
    return new ChecklistEntity(
      "non-existing",
      serialNumber,
      title,
      signed,
      state,
      groups,
      createdBy,
      acceptedBy,
    );
  }

  public static existing(
    uid: string,
    serialNumber: string,
    title: string,
    signed: boolean,
    state: ChecklistState,
    groups: SectionEntity[],
    createdBy: OriginReference | null = null,
    acceptedBy: OriginReference | null = null,
  ): ChecklistEntity {
    return new ChecklistEntity(
      uid,
      serialNumber,
      title,
      signed,
      state,
      groups,
      createdBy,
      acceptedBy,
    );
  }
}
